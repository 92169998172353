import { ref } from '@vue/composition-api'
import { getUserPaymentMethods } from '@api'
import useCryptoJs from '@core/utils/useCryptoJs'

export default function useGame() {
  const { decryptData } = useCryptoJs()

  const paymentMethods = ref([])

  const fetchPaymentMethods = async () => {
    try {
      const response = await getUserPaymentMethods()
      paymentMethods.value = decryptData(response.data)
      paymentMethods.value.unshift({
        id: 0,
        last_4: null,
        exp_month: null,
        exp_year: null,
        brand: 'Add new card',
        exp_date_str: null,
      })
    } catch (e) {
      console.error(e)
      paymentMethods.value = []
    }
  }

  const mapTeams = (players, qtyPlayers) => {
    if (!qtyPlayers || !players || players.length === 0) return []

    const teamSize = qtyPlayers / 2
    const playersA = players.slice(0, teamSize)
    const playersB = players.slice(teamSize)
    const teams = [
      {
        id: 1,
        players: playersA,
        name: 'Equipo A',
      },
      {
        id: 2,
        players: playersB,
        name: 'Equipo B',
      },
    ]

    return teams
  }

  return {
    // data
    paymentMethods,

    // mEthods
    fetchPaymentMethods,
    mapTeams,
  }
}
