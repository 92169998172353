<template>
  <div>
    <v-chip
      v-if="showRemove"
      color="primary"
      text-color="textSecondary"
      small
      class="pa-3 rounded-lg"
      style="color: #979797 !important"
      :append-icon="icons.mdiAccountRemoveOutline"
      @click="$emit('remove-player')"
    >
      {{ t('player_actions.remove') }}
    </v-chip>

    <v-chip
      v-if="showLeave"
      color="primary"
      text-color="textSecondary"
      small
      class="pa-3 rounded-lg"
      style="color: #979797 !important"
      :append-icon="icons.mdiTrayArrowUp"
      @click="$emit('leave-player')"
    >
      {{ t('player_actions.leave') }}
    </v-chip>

    <v-chip
      v-if="showAdd"
      color="primary"
      text-color="textSecondary"
      small
      class="pa-3 rounded-lg"
      style="color: #979797 !important"
      :append-icon="icons.mdiTrayArrowUp"
      @click="$emit('add-guest', true)"
    >
      {{ t('player_actions.add_guest') }}
    </v-chip>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import {
  mdiTrayArrowUp,
  mdiCashCheck,
  mdiAccountRemoveOutline,
  mdiChevronDown,
} from '@mdi/js'
import { useUtils } from '@core/libs/i18n'

export default {
  name: 'PlayerAction',
  props: {
    showRemove: {
      type: Boolean,
      default: false,
    },
    showLeave: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useUtils()
    const actionsComputed = computed(() => [
      { text: '', action: '' },
    ])

    return {
      // computed
      actionsComputed,

      // i18n
      t,

      icons: {
        mdiTrayArrowUp,
        mdiCashCheck,
        mdiAccountRemoveOutline,
        mdiChevronDown,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
